
import {
  Property, Value,
} from '@/types/vjsf';
import {
  Vue, Component, PropSync, Prop,
} from 'vue-property-decorator';

@Component({
  name: 'Code',
})
export default class Code extends Vue {
@PropSync('value', { required: true })
private code: Value|undefined;

@Prop({ required: true })
private readonly property!: Property;
}
